import React from "react";
import { Bar } from "react-chartjs-2";

const RatingDistributionChart = ({
  ratingDistribution,
  setPopUpStats,
  avgRating,
  numReviews,
}) => {
  const totalRatings = Object.values(ratingDistribution).reduce(
    (sum, count) => sum + count,
    0
  );

  const labels = Object.keys(ratingDistribution).map(
    (rating) => `${rating} stars`
  );
  // Ensure a minimum value of 1 for the dataset
  const data = Object.values(ratingDistribution).map((count) => {
    const percentage = Math.round((count / totalRatings) * 100);
    return percentage < 1 ? 1 : percentage;
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: "#fb3640",
        borderWidth: 0,
        borderRadius: 20,
      },
    ],
  };

  const options = {
    legend: {
      display: false, // This will hide the legend
    },
    title: {
      display: false, // This will hide the title
    },
    scales: {
      xAxes: [
        {
          display: false, // Display the y-axis
        },
      ],
      yAxes: [
        {
          display: false, // Display the y-axis
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItems, data) {
          const label = data.labels[tooltipItems[0].index];
          const value = parseFloat(label);
          return value % 1 === 0
            ? `${value.toFixed(0)} ⭐`
            : `${value.toFixed(1)} ⭐`;
        },
        label: function (tooltipItem, data) {
          const percentage = tooltipItem.yLabel;
          return percentage <= 1 ? "<1%" : `${percentage}%`;
        },
      },
    },
  };

  return (
    <div className="popup">
      <div
        className="popup-inner"
        style={{
          width: "65%",
          "max-width": "50rem",
        }}
      >
        <div>
          <h2>Rating breakdown</h2>
          <strong>Average rating: {avgRating}/5 ⭐</strong>
          <p>({numReviews} ratings)</p>

          <div className="chart-container">
            <Bar data={chartData} options={options} height={90} />
          </div>

          <div className="alignHorizontal">
            <h6>
              0<i class="fa-solid fa-star"></i>
            </h6>{" "}
            <h6
              style={{
                "margin-left": "auto",
                "margin-right": "0rem",
              }}
            >
              5<i class="fa-solid fa-star"></i>
            </h6>{" "}
          </div>
          <div className="space"></div>
          <div
            className="hoverMouse"
            onClick={() => {
              setPopUpStats(false);

              document.getElementById("reviewSection").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            }}
          >
            <text className="giveReview">
              <i class="fa-regular fa-pen-to-square"></i> Rate the channel{" "}
            </text>
          </div>
        </div>
        <i
          onClick={() => {
            setPopUpStats(false);
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
    </div>
  );
};

export default RatingDistributionChart;
