import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../node_modules/axios/index";
import { createReview, deleteReview } from "../actions/reviewActions";
import { detailsProduct } from "../actions/productActions";
import { reviewsFromProgram } from "../actions/reviewActions";
import {
  getDetailsUser,
  userAddPlaylist,
  userRemovePlaylist,
} from "../actions/userActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { moodLogos } from "../components/MoodLogos";
import { platformLogos } from "../components/PlatformsLogos";
import PopUp from "../components/PopUp";
import PopUpReviewSubmitted from "../components/PopUpReviewSubmitted";
import Rating from "../components/Rating";
import {
  PRODUCT_REVIEW_CREATE_RESET,
  PRODUCT_REVIEW_DELETE_RESET,
} from "../constants/productConstants";
import StarRating from "../components/StarRating";
import { useMediaQuery } from "react-responsive";
import TopSimilar from "../components/TopSimilar";
import { useAuth } from "../AuthContext";
import PopUpRatingNeeded from "../components/PopUpReviewRatingNeeded";
import LikeCountReview from "../components/LikeCountReview";
import ThumbnailVideoNoLike from "../components/ThumbnailVideoNoLike";
import LaunchVideo from "../components/LaunchVideo";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import Reviews from "../components/Reviews";
import RatingDistributionChart from "../components/RatingDistributionChart";
import ChannelDescription from "../components/ChannelDescription";

export default function ProductScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentUser } = useAuth();

  const dispatch = useDispatch();
  const productId = props.match.params.id;

  /* const [qty, setQty] = useState(1);  */
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    if (currentUser) {
      dispatch(getDetailsUser(currentUser.auth.currentUser.accessToken));
    }
  }, []);

  const listPlaylist = useSelector((state) => state.listPlaylist);
  let { playlist2s } = listPlaylist;

  const reviewsProgram = useSelector((state) => state.reviewsProgram);
  let { reviewsQuery } = reviewsProgram;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);

  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productReviewCreate;

  const productReviewDelete = useSelector((state) => state.productReviewDelete);

  const {
    loading: loadingReviewDelete,
    error: errorReviewDelete,
    success: successReviewDelete,
  } = productReviewDelete;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [moodList, setMoodList] = useState("");
  const [errorMoodList, setErrorMoodList] = useState(false);

  const [nbSliderVideos, setNbSliderVideos] = useState(0);
  const [toggleVideos, setToggleVideos] = useState("Recent");
  const [videosToShow, setVideosToShow] = useState("");

  /*  const [ratingMood, setRatingMood] = useState("");  */

  const isFirstInPlaylist = playlist2s && playlist2s.includes(productId);

  const [isInPlaylist, setIsInPlaylist] = useState("");

  const [videoPlayed, setVideoPlayed] = useState("");
  const [channelPlayed, setChannelPlayed] = useState("");

  const [popUpStats, setPopUpStats] = useState("");

  const [topPositiveReview, setTopPositiveReview] = useState("");
  const [topNegativeReview, setTopNegativeReview] = useState("");

  const ratingCountForBreakdown = 19;

  useEffect(() => {
    setIsInPlaylist(isFirstInPlaylist);
  }, [isFirstInPlaylist]);

  const submitHandler2 = (e) => {
    e.preventDefault(); // preventDefault to prevent page from refreshing when user click on submit

    dispatch(
      userAddPlaylist(productId, currentUser.auth.currentUser.accessToken)
    );
    setIsInPlaylist(true);
    /* refreshPage();  */
  };

  const removeItemPlaylist = (playlist2s) => {
    dispatch(
      userRemovePlaylist(productId, currentUser.auth.currentUser.accessToken)
    );
    setIsInPlaylist(false);
    /* refreshPage();  */
  };

  const selectedMoods = () => {
    var selected = [];
    var checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      selected.push(checkboxes[i].value);
    }

    /* setRatingMood(selected);  */
    setMoodList(selected);
  };

  useEffect(() => {
    if (successReviewCreate) {
      /* window.alert("Review Submitted Successfully");  */
      setButtonPopupReviewSub(true);
      setRating("");
      setMoodList("");
      setComment("");
      /* setRatingMood("");  */
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }

    if (successReviewDelete) {
      dispatch({ type: PRODUCT_REVIEW_DELETE_RESET });
    }

    dispatch(detailsProduct(productId));
    dispatch(reviewsFromProgram(productId));
  }, [dispatch, productId, successReviewCreate, successReviewDelete]);

  /*
  const addToPlaylistHandler = () => {
    props.history.push(`/favorites/${productId}?qty=${qty}`);
  }; */
  const submitHandler = (e) => {
    e.preventDefault(); // preventDefault to prevent page from refreshing when user click on submit
    if (moodList.length > 4) {
      setErrorMoodList(true);
    } else if (
      rating /* && comment && ratingFun && ratingLearn && ratingEmotional */
    ) {
      dispatch(
        createReview(productId, {
          rating,
          moodList,
          comment,
          /* ratingMood,  */
          name: user.userName || currentUser.displayName,
          uid: currentUser.uid,
          userId: user.userId,
          token: currentUser.auth.currentUser.accessToken,
          programName: product.name,
        })
      );
    } else {
      /*  alert("Please enter the general rating");  */
      setPopupRatingNeeded(true);
    }
  }; // e = event

  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopupReviewSub, setButtonPopupReviewSub] = useState(false);
  const [popupRatingNeeded, setPopupRatingNeeded] = useState(false);

  const [similarProgramsSameTopic, setProducts] = useState([]);

  /* const [similarProgramsOtherTopic, setOtherProducts] = useState([]);  */

  async function launchTopSimilar(x) {
    let responseSimilarTable = await axios.get(
      process.env.REACT_APP_API_ADDRESS + `similars/` + x
    );
    const similarTable = responseSimilarTable.data;

    var similarList = similarTable
      .slice(0, 10)
      .map(function (item) {
        return item["similarNameID"];
      })
      .join("_");

    /*let similarList = similarTable.similarNameID.join("-"); */

    /*
    [1, 2, 3, 4, 5, 6, 7, 8, 9].map(
      (x) => (similarList = similarList + "_" + similarTable[x].similarNameID)
    );  */
    let responseRanking = await axios.get(
      process.env.REACT_APP_API_ADDRESS +
        `products/channels/${similarList}/getvideos/false`
    );
    let similarSamePrograms = [];
    similarTable
      .map((a) => a.similarNameID)
      .slice(0, 10)
      .forEach((k) => {
        let n = responseRanking.data.filter((obj) => {
          return obj._id === k;
        });
        if (n.length > 0) {
          similarSamePrograms.push(n[0]);
        }
      });

    /*
    let similarOtherPrograms = [];
    similarTable
      .filter((character) => character.sameSubTopic < 1)
      .map((a) => a.similarNameID)
      .slice(0, 5)
      .forEach((k) => {
        let n = responseRanking.data.filter((obj) => {
          return obj._id === k;
        });
        if (n.length > 0) {
          similarOtherPrograms.push(n[0]);
        }
      }); */
    setProducts(similarSamePrograms);
    /* setOtherProducts(similarOtherPrograms);  */
  }

  useEffect(() => {
    launchTopSimilar(productId);
  }, []);

  const isMobile = !useMediaQuery({
    query: "(min-width: 808px)",
  });

  useEffect(() => {
    if (product && product.lastVideos) {
      setVideosToShow(
        product.lastVideos
          .filter((a) => a.nbViews >= 1)

          .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
          .slice(nbSliderVideos, nbSliderVideos + 3)
      );
    }
  }, [product]);

  useEffect(() => {
    setTopPositiveReview("");
    setTopPositiveReview("");

    if (reviewsQuery?.length > 12) {
      setTopPositiveReview(
        reviewsQuery
          .slice(0)
          .reverse()
          .filter((review) => review.comment.length >= 100)
          .filter((review) => review.rating > 3)
          .filter((review) => review.likeCount >= 2)
          .sort((a, b) => b.likeCount - a.likeCount)
          .slice(0, 1)
      );

      setTopNegativeReview(
        reviewsQuery
          .slice(0)
          .reverse()
          .filter((review) => review.comment.length >= 100)
          .filter((review) => review.rating <= 3)
          .filter((review) => review.likeCount >= 2)
          .sort((a, b) => b.likeCount - a.likeCount)
          .slice(0, 1)
      );
    }
  }, [reviewsQuery]);

  const generateAggregateRatingSchema = (product) => {
    if (!product.numReviews || product.numReviews <= 3) return null;
    return {
      "@context": "http://schema.org",
      "@type": "AggregateRating",
      itemReviewed: {
        "@type": "CreativeWorkSeries",
        name: product.name,
        image: product.image,
      },
      ratingValue: Math.round(product.rating * 10) / 10,
      bestRating: "5",
      worstRating: "0.5",
      reviewCount: product.numReviews,
    };
  };

  return (
    <div className="mainElement">
      <Helmet>
        <title>
          {product && product.name
            ? "Reviews of " + product.name + " and similar YouTube channels"
            : "YouTube channel"}{" "}
          | Favoree
        </title>
        <meta
          name="description"
          content={
            product && product.name && product.allTopics[0]
              ? "Find similar YouTube channels to " +
                product.name +
                ". Discover user ratings and reviews. " +
                product.name +
                ", a YouTube channel about " +
                product.allTopics[0].subTopic +
                ", " +
                product.allTopics[0].mainTopic +
                ". Top 10 YouTubers like " +
                product.name +
                "."
              : "Learn more about this YouTube channel"
          }
        ></meta>
        <link
          rel="canonical"
          href={
            product?._id
              ? `https://www.favoree.io/channel/${product.name
                  .replaceAll(" ", "_")
                  .replaceAll("-", "_")
                  .toLowerCase()}-${product._id}`
              : "https://www.favoree.io/channel/summoning_salt-639912491f9e9229a0861a30"
          }
        ></link>
        {product?.numReviews && product.numReviews > 3 ? (
          <script type="application/ld+json">
            {JSON.stringify(generateAggregateRatingSchema(product))}
          </script>
        ) : null}

        {product?.image ? (
          <meta
            property="og:image"
            content={
              product.image
                ? product.image
                : "https://www.favoree.io/images/allChannels.png"
            }
          />
        ) : null}
        {product?.image ? (
          <meta
            name="twitter:image"
            content={
              product.image
                ? product.image
                : "https://www.favoree.io/images/allChannels.png"
            }
          />
        ) : null}
      </Helmet>
      {loading ? (
        <div>
          <div className="space"></div>
          <div className="space"></div>
          <LoadingBox></LoadingBox>
        </div>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : !product[0] ? (
        <div className="productPage">
          <noscript>
            {" "}
            <Link to="/">Back to result</Link>{" "}
          </noscript>
          <div className="row top">
            <div className="col1-ProductDescription">
              <div
                style={{
                  width: "100%",
                  "min-height": isMobile ? "150px" : "300px",
                }}
              >
                <img
                  className="large"
                  src={product.image}
                  onError={(e) => {
                    e.target.src = product.lastVideos.at(-1).thumbnail;
                    e.target.className = "thumbnailOnChannelPage";
                  }}
                  alt={"Picture of the YouTube channel " + product.name}
                ></img>
              </div>

              {!isMobile && (
                <ChannelDescription
                  product={product}
                  similarProgramsSameTopic={similarProgramsSameTopic}
                ></ChannelDescription>
              )}
            </div>
            <div className="col2-ProductDescription">
              <div className="space"></div>

              <ul>
                <li>
                  <h1>{product.name}</h1>
                </li>

                <li>
                  <div
                    className="alignHorizontal"
                    style={{ "flex-wrap": "wrap", gap: "2rem" }}
                  >
                    <div
                      className="hoverMouse"
                      onClick={() => {
                        document
                          .getElementById("reviewSection")
                          .scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "start",
                          });
                      }}
                    >
                      <Rating
                        mean={true}
                        rating={product.rating}
                        numReviews={product.numReviews}
                      ></Rating>
                    </div>
                    {product.numReviews > ratingCountForBreakdown && (
                      <i
                        class="fa-solid fa-square-poll-vertical"
                        style={{
                          "font-size": "2.8rem",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPopUpStats(true);
                        }}
                      ></i>
                    )}
                    <div
                      className="hoverMouse"
                      onClick={() => {
                        document
                          .getElementById("reviewSection")
                          .scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "start",
                          });
                      }}
                    >
                      <text className="giveReview">
                        <i class="fa-regular fa-pen-to-square"></i>{" "}
                        {isMobile ? "Rate channel" : "Rate and review"}{" "}
                      </text>
                    </div>
                  </div>
                  {product.rating_distribution &&
                  product.numReviews > ratingCountForBreakdown &&
                  popUpStats ? (
                    <RatingDistributionChart
                      ratingDistribution={product.rating_distribution}
                      setPopUpStats={setPopUpStats}
                      avgRating={Math.round(product.rating * 10) / 10}
                      numReviews={product.numReviews}
                    />
                  ) : (
                    <div></div>
                  )}
                </li>

                <li>
                  <div className="topics">
                    <ul>
                      <Link to={"/search?category=all_" + product.category}>
                        <a className="topic">{product.category} </a>{" "}
                      </Link>
                      {[
                        ...new Set(
                          product.allTopics.map((item) => item.mainTopic)
                        ),
                      ].map((x) => (
                        <Link to={"/search?allTopics=all_" + x}>
                          <a className="topic">{x} </a>
                        </Link>
                      ))}

                      {product.allTopics &&
                        product.allTopics
                          .filter((topic) => topic.subTopic !== "Multi-topic")
                          .map((topic) => (
                            <Link to={"/search?subTopic=all_" + topic.subTopic}>
                              <a className="topic">{topic.subTopic} </a>{" "}
                            </Link>
                          ))}
                      {product.tag?.map((x) => (
                        <li key={x}>
                          <Link to={"/search?tag=" + x}>
                            <a className="topic">{x} </a>{" "}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="space"></div>

                  {isMobile && (
                    <ChannelDescription
                      product={product}
                      similarProgramsSameTopic={similarProgramsSameTopic}
                    ></ChannelDescription>
                  )}
                </li>
                {product.rankingRatingMoodDef ? (
                  <h3>People find it</h3>
                ) : (
                  <div></div>
                )}
                {product.rankingRatingMoodDef ? (
                  <div
                    className="hoverMouse"
                    onClick={() => {
                      document.getElementById("reviewSection").scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start",
                      });
                    }}
                  >
                    {product.rankingRatingMoodDef.map((x) => (
                      <text>
                        <i
                          className={
                            moodLogos.find((y) => y.name === x)
                              ? moodLogos.find((y) => y.name === x)
                                  .emoticonClass
                              : ""
                          }
                        ></i>{" "}
                        {x.replace("Fun", "Funny").replace("Funnyny", "Funny")}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </text>
                    ))}{" "}
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card card-body3">
                  <div></div>
                  <div className="oneThird">
                    <div>
                      <h3 style={{ "font-size": "1.6rem", margin: "0rem" }}>
                        Subscribers
                      </h3>{" "}
                    </div>
                    <div className="space"></div>
                    <div>
                      <text>
                        <i className="fa-solid fa-user-group"></i>
                        &nbsp;&nbsp;
                        {product.subscriberCount > 1000000
                          ? Math.round(product.subscriberCount / 100000) / 10 +
                            "M"
                          : product.subscriberCount > 10000
                          ? Math.round(product.subscriberCount / 1000) + "K"
                          : product.subscriberCount > 1000
                          ? Math.round(product.subscriberCount / 100) / 10 + "K"
                          : product.subscriberCount}
                      </text>{" "}
                    </div>
                  </div>
                  <div className="oneThird">
                    <div>
                      <h3 style={{ "font-size": "1.6rem", margin: "0rem" }}>
                        Average video duration
                      </h3>
                    </div>
                    <div className="space"></div>
                    <div>
                      <text>{product.duration + " minutes"}</text>
                    </div>
                  </div>
                  <div className="oneThird">
                    <div>
                      <h3 style={{ "font-size": "1.6rem", margin: "0rem" }}>
                        Country & language
                      </h3>
                    </div>
                    <div className="space"></div>
                    <div>
                      <text>
                        {product.country} - {product.language}
                      </text>
                    </div>
                  </div>
                </div>
                <div className="space"></div>

                <div className="alignHorizontal">
                  <div>
                    <h3>Go to channel</h3>
                    <div className="alignHorizontal">
                      {product.platform.map((c) => (
                        <li key={c}>
                          <a
                            href={product.link.filter((item) =>
                              item.includes(c.split(" ")[0].toLowerCase())
                            )}
                            target="_blank"
                          >
                            <img
                              className="logoActive"
                              src={
                                platformLogos.filter((item) =>
                                  item.includes(c.replace(/\s/g, ""))
                                )[0]
                              }
                              alt={c}
                            />
                          </a>
                        </li>
                      ))}
                    </div>
                  </div>

                  {isInPlaylist ? (
                    <button
                      onClick={removeItemPlaylist}
                      className="buttondelete"
                    >
                      Added to your favorites{" "}
                      <i className="fa-solid fa-heart"></i>
                    </button>
                  ) : !currentUser ? (
                    <button
                      onClick={() => setButtonPopup(true)}
                      className="primary block"
                    >
                      Add to favorites <i className="fa-regular fa-heart"></i>
                    </button>
                  ) : (
                    <button onClick={submitHandler2} className="primary block">
                      Add to favorites <i className="fa-regular fa-heart"></i>
                    </button>
                  )}
                </div>
                <div className="space"></div>

                <PopUp
                  trigger={buttonPopup}
                  setTrigger={setButtonPopup}
                ></PopUp>
                <PopUpReviewSubmitted
                  trigger={buttonPopupReviewSub}
                  setTrigger={setButtonPopupReviewSub}
                ></PopUpReviewSubmitted>
                <PopUpRatingNeeded
                  trigger={popupRatingNeeded}
                  setTrigger={setPopupRatingNeeded}
                ></PopUpRatingNeeded>
              </ul>
            </div>
          </div>
          {topPositiveReview.length > 0 && topNegativeReview.length > 0 ? (
            <div className="block-review">
              <div className="ninetyPercent">
                <div className="hr-line">
                  <hr></hr>
                </div>
                <div className="alignHorizontal">
                  <h2>Popular reviews</h2>{" "}
                  <a
                    className="toRight hoverMouse"
                    onClick={() => {
                      document.getElementById("reviewSection").scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start",
                      });
                    }}
                  >
                    View more
                  </a>
                </div>
                <div className="coverInside" id="coverReviews">
                  <div className="row center" key={productId}>
                    <Reviews
                      reviewList={topPositiveReview}
                      currentUser={currentUser}
                      channelNameRemoved={true}
                    ></Reviews>
                    <div>
                      <div className="vl">
                        <div className="VS">
                          <strong>VS</strong>
                        </div>
                      </div>
                    </div>
                    <Reviews
                      reviewList={topNegativeReview}
                      currentUser={currentUser}
                      channelNameRemoved={true}
                    ></Reviews>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div> </div>
          )}

          <div className="space"> </div>
          <div className="block-review">
            <div className="ninetyPercent">
              <div>
                {similarProgramsSameTopic && similarProgramsSameTopic[0] ? (
                  <div>
                    <div className="space"></div>
                    <div className="hr-line">
                      <hr></hr>
                    </div>
                    <div className="space"></div>
                    <h2>Similar channels to {product.name}</h2>
                    <div className="space"></div>

                    <TopSimilar
                      titleTop=""
                      productList={similarProgramsSameTopic}
                    ></TopSimilar>
                    <div className="space"></div>
                    <div className="space"></div>
                  </div>
                ) : (
                  <div></div>
                )}

                {product?.lastVideos && (
                  <div>
                    <div className="hr-line">
                      <hr></hr>
                    </div>
                    <div className="alignHorizontal" id="toRight">
                      {" "}
                      <h2>Videos</h2>{" "}
                      <div className="rightAlign">
                        <button
                          className="sortVideos"
                          id={
                            toggleVideos == "Recent" ? "sortVideosOn" : "other"
                          }
                          onClick={() => {
                            setToggleVideos("Recent");
                            setVideosToShow(
                              product.lastVideos
                                .filter((a) => a.nbViews >= 1)
                                .sort(
                                  (a, b) =>
                                    new Date(b.publishDate) -
                                    new Date(a.publishDate)
                                )
                                .slice(nbSliderVideos, nbSliderVideos + 3)
                            );
                          }}
                        >
                          {isMobile ? "Recent" : "Most recent"}
                        </button>
                        <button
                          className="sortVideos"
                          id={
                            toggleVideos == "Popular" ? "sortVideosOn" : "other"
                          }
                          onClick={() => {
                            setToggleVideos("Popular");
                            setVideosToShow(
                              product.lastVideos &&
                                product.lastVideos
                                  .sort((a, b) => b.nbViews - a.nbViews)
                                  .slice(nbSliderVideos, nbSliderVideos + 3)
                            );
                          }}
                        >
                          {isMobile ? "Popular" : "Most popular"}
                        </button>
                        {product.VideosFromOtherChannels && (
                          <button
                            className="sortVideos"
                            id={
                              toggleVideos == "Featured"
                                ? "sortVideosOn"
                                : "other"
                            }
                            onClick={() => {
                              setToggleVideos("Featured");
                              setVideosToShow(
                                product.VideosFromOtherChannels.sort(
                                  (a, b) =>
                                    new Date(b.publishDate) -
                                    new Date(a.publishDate)
                                )
                              );
                            }}
                          >
                            {isMobile
                              ? "Featured"
                              : "Featured on other channels"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row center">
                      {videosToShow &&
                        videosToShow.map((c) => (
                          <ThumbnailVideoNoLike
                            c={c}
                            setVideoPlayed={setVideoPlayed}
                            setChannelPlayed={setChannelPlayed}
                            channelName={
                              toggleVideos == "Featured" && c.channelName
                                ? c.channelName
                                : product.name
                            }
                            channelId={
                              toggleVideos == "Featured" && c.channelId
                                ? c.channelId
                                : product._id
                            }
                          ></ThumbnailVideoNoLike>
                        ))}
                    </div>
                    <div className="space"></div>

                    <div className="space"></div>
                  </div>
                )}
              </div>

              <div id="reviewSection">
                <div className="hr-line">
                  <hr></hr>
                </div>
                <div className="space"></div>
                <div className="alignHorizontal" id="toRight">
                  <h2 id="reviews">
                    {product?.name
                      ? "Reviews of " + product?.name
                      : "User reviews"}{" "}
                  </h2>
                  {!isMobile &&
                    product.numReviews > ratingCountForBreakdown && (
                      <div className="rightAlign">
                        <button
                          className="sortVideos"
                          id={"other"}
                          onClick={() => {
                            setPopUpStats(true);
                          }}
                        >
                          <i class="fa-solid fa-square-poll-vertical"></i>{" "}
                          Rating stats
                        </button>
                      </div>
                    )}
                </div>
                <div className="space"></div>
                {currentUser &&
                reviewsQuery &&
                reviewsQuery.find((x) => x.uid === currentUser.uid) ? (
                  <div>
                    <label htmlFor="rating">
                      You already submitted a review ✅
                    </label>
                    <div className="space"></div>
                    <label htmlFor="rating">
                      You can remove it by clicking on "Remove my review"
                    </label>
                    <div className="space"> </div>
                  </div>
                ) : currentUser ? (
                  <form className="reviewSubmit" onSubmit={submitHandler}>
                    <div></div>
                    <div className="alignHorizontal">
                      <div className="alignVertical">
                        <h3 htmlFor="rating">General rating </h3>
                        <div className="selectRating">
                          <StarRating passChildData={setRating}></StarRating>
                        </div>
                      </div>
                      <div className="alignVertical">
                        <h3 htmlFor="rating">
                          What best describes this channel?
                        </h3>

                        <div className="selectRating">
                          {[
                            "Trustworthy",
                            "Unreliable",
                            "Relaxing",
                            "Instructional",
                            "Funny",
                            "Serious",
                            "Inspiring",
                            "Uplifting",
                            "Concise",
                            "Lengthy",
                            "Complex",
                            "Kid-friendly",
                          ].map((c) => (
                            <li key={c} id={c}>
                              <input
                                type="checkbox"
                                name="type"
                                value={c}
                                onClick={() => selectedMoods()}
                              />
                              <a className="active">{c}</a>{" "}
                            </li>
                          ))}
                        </div>
                        {moodList.length > 4 && (
                          <MessageBox variant="danger">
                            🚨 You can't select more than 4 tags
                          </MessageBox>
                        )}
                      </div>
                    </div>
                    <div className="space"></div>
                    <div>
                      <textarea
                        id="comment"
                        placeholder=" Write a comment..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                    <div>
                      <label />
                    </div>
                    <div>
                      {loadingReviewCreate && <LoadingBox> </LoadingBox>}
                      {errorReviewCreate && (
                        <MessageBox variant="danger">
                          {errorReviewCreate.replace(
                            "Invalid Token",
                            "Session has expired, please refresh the page"
                          )}
                        </MessageBox>
                      )}
                    </div>
                    <div className="space"></div>

                    {errorMoodList && moodList.length > 4 ? (
                      <MessageBox variant="danger">
                        😱 Please select less than 5 tags to describe the
                        channel
                      </MessageBox>
                    ) : (
                      <div></div>
                    )}

                    <button className="primary" type="submit">
                      {" "}
                      Post review{" "}
                    </button>
                  </form>
                ) : (
                  <div>
                    <form
                      className="reviewSubmit"
                      onClick={() => setButtonPopup(true)}
                    >
                      <div></div>
                      <div className="alignHorizontal">
                        <div className="alignVertical">
                          <h3 htmlFor="rating">General rating </h3>
                          <div className="selectRating">
                            <StarRating passChildData={setRating}></StarRating>
                          </div>
                        </div>
                        <div className="alignVertical">
                          <h3 htmlFor="rating">
                            What best describes this channel?
                          </h3>
                          <div className="selectRating">
                            {[
                              "Trustworthy",
                              "Unreliable",
                              "Relaxing",
                              "Instructional",
                              "Funny",
                              "Serious",
                              "Inspiring",
                              "Uplifting",
                              "Concise",
                              "Lengthy",
                              "Complex",
                              "Kid-friendly",
                            ].map((c) => (
                              <li key={c}>
                                <input type="checkbox" name="type" value={c} />
                                <a className="active">{c}</a>{" "}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="space"></div>
                      <div>
                        <textarea
                          id="comment"
                          placeholder=" Write a comment..."
                        ></textarea>
                      </div>
                      <div>
                        <label />
                      </div>
                      <div>
                        {loadingReviewCreate && <LoadingBox> </LoadingBox>}
                        {errorReviewCreate && (
                          <MessageBox variant="danger">
                            {errorReviewCreate}
                          </MessageBox>
                        )}
                      </div>
                      <div className="space"></div>

                      <button type="button" className="primary block">
                        {" "}
                        Post review{" "}
                      </button>
                    </form>

                    <div className="space"> </div>
                    <MessageBox>
                      Please <Link to="/signin">Sign In</Link> to write a review
                    </MessageBox>
                    <div className="space"></div>
                  </div>
                )}

                {!reviewsQuery || reviewsQuery.length == 0 /* |
            (product.reviews.map((review) => review.comment) == "") */ ? (
                  <MessageBox>
                    <div className="space"></div>
                    No review to be shown for now, be the first to write one 😉
                  </MessageBox>
                ) : (
                  <div></div>
                )}

                {reviewsQuery && reviewsQuery.length > 0 ? (
                  <ul>
                    <div className="space"></div>
                    <div className="space"></div>

                    {reviewsQuery
                      .slice(0)
                      .reverse()
                      .sort((a, b) => b.comment.length - a.comment.length)
                      .slice(0, 50)

                      /*
                .filter(function (review) {
                  return review.comment.length > 1;
                }) */
                      .map((review) => (
                        <li className="review" key={review._id}>
                          <div className="row">
                            <div className="alignHorizontal" id="totalWidth">
                              <Link to={`/user/${review.userId}`}>
                                <strong id="italic">{review.userName}</strong>
                              </Link>
                              <Rating
                                caption=" "
                                rating={review.rating}
                              ></Rating>

                              {review._id &&
                              review.comment &&
                              review.comment.length > 1 &&
                              (!review.removedByModeration ||
                                (review.removedByModeration &&
                                  review.uid == currentUser?.uid)) ? (
                                <div className="likeReview">
                                  <LikeCountReview
                                    reviewId={review._id}
                                    userToken={
                                      currentUser
                                        ? currentUser.auth.currentUser
                                            .accessToken
                                        : ""
                                    }
                                    userIdReviewing={
                                      review.uid ? review.uid : ""
                                    }
                                    uid={currentUser ? currentUser.uid : ""}
                                  ></LikeCountReview>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                            <div className="column1">
                              <p>
                                {review.removedByModeration &&
                                review.uid != currentUser?.uid
                                  ? ""
                                  : review.comment}
                              </p>
                            </div>
                            {review.moodList != "" ? (
                              <div className="column2">
                                <a>This user found this content: </a>
                                <div className="topics">
                                  <ul>
                                    {review.moodList.map((c) => (
                                      <a className="topic"> {c} </a>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <div className="column1">
                              <h5>
                                {"Published " +
                                  review.createdAt.substring(0, 10)}
                              </h5>
                              <div className="space"></div>
                            </div>
                          </div>
                          {currentUser && review.uid == currentUser.uid ? (
                            <button
                              className="buttondelete"
                              onClick={() => {
                                dispatch(
                                  deleteReview(
                                    product._id,
                                    currentUser.auth.currentUser.accessToken
                                  )

                                  /* window.location.reload()  */
                                );
                              }}
                            >
                              Remove my review{" "}
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          ) : (
                            <div> </div>
                          )}
                        </li>
                      ))}
                  </ul>
                ) : (
                  <div> </div>
                )}
              </div>
              <div className="space"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>

          <h3>Loading</h3>
        </div>
      )}
      {videoPlayed && (
        <LaunchVideo
          videoPlayed={videoPlayed}
          channelPlayed={channelPlayed}
          setVideoPlayed={setVideoPlayed}
          setChannelPlayed={setChannelPlayed}
        ></LaunchVideo>
      )}
    </div>
  );
}
